import styled from "@emotion/styled";
import { Button } from "@ibeckinc/ui-elements";
import Image from "next/image";
import React, { FunctionComponent } from "react";

import { NextLink } from "../../../components/NextLink";
import { useMobile } from "../../../hooks/useMobile";
import { ICompany } from "../../../lib/interfaces/Company";
import { device } from "../../../utils";
import TitleAndDescription from "./TitleAndDescription";

interface Props {
  supporters: ICompany[];
}

const SupporterArea: FunctionComponent<Props> = ({ supporters }) => {
  const { isMobile } = useMobile();

  return (
    <Root>
      <TitleAndDescription
        title="名だたるサポーター"
        body={`弊社の「自身の方向性を描くキャリアデザイン」「キャリアデザインを実現するための人財育成」「選択肢を選択肢化するための選考対策」「質の高い成長機会提供・情報提供」という理念に共感頂いている提携企業様です。提携企業様からは、日本唯一の機会提供をされていることも多く、他社・他コミュニティを抑え、圧倒的評価を頂いています。`}
      />
      <LogoContainer>
        <LogosWrapper>
          {supporters.map(({ id, logo }) => (
            <NextLink key={id} href={`/company/${id}`}>
              <LogoWrapper>
                <Image
                  src={logo}
                  alt={`supporter-company-logo-${id}`}
                  width={isMobile() ? 72 : 120}
                  height={isMobile() ? 72 : 120}
                  priority
                />
              </LogoWrapper>
            </NextLink>
          ))}
        </LogosWrapper>
      </LogoContainer>
      <ButtonWrapper>
        <NextLink href="/companies">
          <_Button>もっと見る</_Button>
        </NextLink>
      </ButtonWrapper>
    </Root>
  );
};

const Root = styled.section`
  width: 1000px;
  margin: 120px auto;

  @media ${device.mobile} {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 60px auto;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const LogosWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  @media ${device.mobile} {
    gap: 8px;
    width: 312px;
  }
`;

const LogoWrapper = styled.div`
  box-sizing: border-box;
  border-radius: 4px;
  height: 120px;
  width: 120px;
  overflow: hidden;

  @media ${device.pc} {
    border: 1px solid #e0e1e3;
  }

  @media ${device.mobile} {
    height: 72px;
    width: 72px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 32px;
`;

const _Button = styled(Button)`
  width: 200px;
`;

export default SupporterArea;
