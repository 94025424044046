import * as React from "react";

const AboutGrowthIcon: React.SVGAttributes<SVGElement> & React.FC = ({}) => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="52.0308" cy="27.9688" r="22.9688" fill="#E5F3FE" />
    <path
      d="M29.0625 75.0005H26.875V70.6255H7.1875V75.0005H5V69.5317C5 68.928 5.48999 68.438 6.09375 68.438H27.9688C28.5725 68.438 29.0625 68.928 29.0625 69.5317V75.0005Z"
      fill="#00A4DF"
    />
    <path
      d="M50.9375 75.001H48.75V64.0635H29.0625V66.251H26.875V62.9697C26.875 62.366 27.365 61.876 27.9688 61.876H49.8438C50.4475 61.876 50.9375 62.366 50.9375 62.9697V75.001Z"
      fill="#00A4DF"
    />
    <path
      d="M75 75.0005H72.8125V57.5005H50.9375V59.688H48.75V56.4067C48.75 55.803 49.24 55.313 49.8438 55.313H73.9062C74.51 55.313 75 55.803 75 56.4067V75.0005Z"
      fill="#00A4DF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.7188 15.9375C33.7033 15.9375 31.25 13.4842 31.25 10.4688C31.25 7.45328 33.7033 5 36.7188 5C39.7342 5 42.1875 7.45328 42.1875 10.4688C42.1875 13.4842 39.7342 15.9375 36.7188 15.9375ZM36.7188 7.1875C34.9097 7.1875 33.4375 8.65968 33.4375 10.4688C33.4375 12.2778 34.9097 13.75 36.7188 13.75C38.5278 13.75 40 12.2778 40 10.4688C40 8.65968 38.5278 7.1875 36.7188 7.1875Z"
      fill="#00A4DF"
    />
    <path
      d="M15.9373 31.25C15.6573 31.25 15.3773 31.1428 15.164 30.9295L11.8828 27.6483C11.4551 27.2206 11.4551 26.5294 11.8828 26.1017L19.539 18.4454C19.6735 18.3109 19.8409 18.2136 20.0246 18.1633L32.0559 14.882L32.6312 16.9929L20.8811 20.1976L14.2026 26.875L15.9373 28.6097L21.7265 22.8204C21.8665 22.6804 22.0426 22.5809 22.234 22.5328L30.984 20.3453L31.5156 22.4672L23.0587 24.5814L16.7106 30.9295C16.4973 31.1428 16.2173 31.25 15.9373 31.25H15.9373Z"
      fill="#00A4DF"
    />
    <path
      d="M47.6574 52.0308C47.2844 52.0308 46.9224 51.8394 46.7189 51.5003L40.3883 40.9511L32.078 38.873L32.6096 36.7511L41.3596 38.9386C41.6407 39.0086 41.8824 39.188 42.0311 39.4363L48.073 49.5053L50.5996 48.2431L45.7269 37.5233L35.1361 32.2284C34.6658 31.9922 34.4285 31.4552 34.574 30.9488L36.7615 23.2925C36.8829 22.8659 37.2503 22.5553 37.6922 22.5061L47.1543 21.455L53.766 14.8433L52.0313 13.1086L46.2421 18.8978C46.0375 19.1035 45.7586 19.2183 45.4688 19.2183H37.8125V17.0308H45.016L51.258 10.7888C51.6857 10.3611 52.3769 10.3611 52.8046 10.7888L56.0858 14.07C56.5135 14.4977 56.5135 15.1889 56.0858 15.6166L48.4296 23.2728C48.2535 23.4489 48.0238 23.5594 47.7766 23.5867L38.6624 24.5995L36.9266 30.6775L47.0514 35.7394C47.2757 35.852 47.4539 36.0369 47.5579 36.2655L53.0266 48.2967C53.2705 48.8327 53.0463 49.4649 52.5202 49.7285L48.1452 51.916C47.9888 51.9936 47.8214 52.0308 47.6574 52.0308L47.6574 52.0308Z"
      fill="#00A4DF"
    />
    <path
      d="M18.1253 57.4997C17.8442 57.4997 17.5642 57.3914 17.352 57.1792L14.0707 53.898C13.6431 53.4703 13.6431 52.7791 14.0707 52.3514L22.6381 43.7841L25.7979 34.3045L26.9179 22.118L29.0956 22.317L27.9646 34.6305C27.957 34.7147 27.9395 34.7967 27.9133 34.8766L24.632 44.7203C24.5773 44.8811 24.4876 45.0277 24.3673 45.148L16.3906 53.1247L18.1986 54.9327L29.205 45.9278L32.39 35.3108L34.4856 35.9386L31.2043 46.8761C31.1409 47.0861 31.0184 47.271 30.8489 47.4088L18.8176 57.2525C18.6153 57.4177 18.3703 57.4997 18.1253 57.4997Z"
      fill="#00A4DF"
    />
  </svg>
);

export default AboutGrowthIcon;
