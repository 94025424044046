import styled from "@emotion/styled";
import { Color } from "@ibeckinc/ui-elements";
import Image from "next/image";
import React, { FunctionComponent, useMemo } from "react";

import { useMobile } from "../../../hooks/useMobile";
import { device } from "../../../utils";
import TitleAndDescription from "./TitleAndDescription";

const body = `選考倍率50倍〜200倍にものぼる外資系戦略コンサル・投資銀行・商社などのトップ企業への選考対策を\n実施し培ってきた内定実績です。\n外資系戦略コンサルティングファーム・外資系投資銀行・外資系消費財メーカー等内定付与数内弊社会員\nは、6〜9割強を占め、7年連続日本一位の実績を残しています。MBB・GSMSJPの内定取得者数は、2018年開始依頼520名超を輩出。\nそれ以外にも、ある大学から初めて外資系戦略ファームへの内定等実現しております。`;

const AchievementArea: FunctionComponent = () => {
  const { isMobile } = useMobile();

  const title = useMemo(() => {
    return isMobile()
      ? `確固たる実績`
      : `「人財育成」の方向性の正しさを立証する確固たる実績`;
  }, [isMobile]);

  return (
    <Root>
      <_TitleAndDescription title={title} body={body} />
      <ArchivementImgContainer>
        <ArchivementImgArea />
        <ArchivementImg width={isMobile() ? 335 : 1000}>
          {isMobile() ? (
            <Image
              key="achivement-sp"
              src="/images/achivement-sp@2x.png"
              alt="内定実績"
              width={335}
              height={664}
              priority
            />
          ) : (
            <Image
              key="achivement"
              src="/images/achivement@2x.png"
              alt="内定実績"
              width={968}
              height={432}
              priority
            />
          )}
        </ArchivementImg>
      </ArchivementImgContainer>
    </Root>
  );
};

const Root = styled.section`
  margin: 0px auto 140px auto;
  @media ${device.mobile} {
    margin: 80px auto 0 auto;
  }
`;

const _TitleAndDescription = styled(TitleAndDescription)`
  width: 1000px;
  margin: 120px auto 59px auto;

  @media ${device.mobile} {
    width: auto;
    margin: 60px 0px;
  }
`;

const ArchivementImgContainer = styled.div`
  box-sizing: border-box;
  height: 410px;
  width: 100%;

  @media ${device.mobile} {
    width: 100%;
    height: 652px;
  }
`;

const ArchivementImgArea = styled.div`
  @media ${device.pc} {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      height: 412px;
      width: 100vw;
      min-width: 1000px;
      margin-top: -16px;
      right: 14px;
      background: ${Color.Background.LightBrand};
    }
  }

  @media ${device.mobile} {
    &::before {
      content: "";
      position: absolute;
      height: 652px;
      width: 100vw;
      right: 0;
      margin-top: -24px;
      background: ${Color.Background.LightBrand};
    }
  }
`;

const ArchivementImg = styled.div<{ width: number }>`
  margin: 0 auto;
  width: ${({ width }) => width}px;

  @media ${device.mobile} {
    padding-left: 0;
  }
`;

export default AchievementArea;
