import styled from "@emotion/styled";
import { Background, Typography } from "@ibeckinc/ui-elements";
import React, { FunctionComponent } from "react";

import { device } from "../../../utils";

const { Text, Title } = Typography;

export type Props = {
  className?: string;
  icon: JSX.Element;
  title: string;
  body: string;
};

const UpcomerCard: FunctionComponent<Props> = ({
  className,
  icon,
  title,
  body,
}) => {
  return (
    <Root className={className}>
      <IconContainer>{icon}</IconContainer>
      <TitleContainer>
        <Title level={3}>{title}</Title>
      </TitleContainer>
      <BodyContainer>
        <Text multiline>{body}</Text>
      </BodyContainer>
      <BrandLineContainer>
        <BrandLine />
      </BrandLineContainer>
    </Root>
  );
};

const Root = styled.div`
  background: ${Background.Base};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 224px;
  min-width: 224px;
  height: 340px;
  padding: 30px 0 0 0;

  @media ${device.mobile} {
    height: 308px;
  }
`;

const BrandLineContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 2;
`;

const BrandLine = styled.div`
  background: ${Background.Brand};
  height: 4px;
  width: 100%;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleContainer = styled.div`
  margin: 25px 0 21px 0;
  text-align: center;
`;

const BodyContainer = styled.div`
  margin: 0 14px;
`;

export default UpcomerCard;
