import styled from "@emotion/styled";
import Image from "next/image";
import React, { FunctionComponent } from "react";

import BannerSlider from "../../components/BannerSlider";
import { useGetBanners } from "../../components/BannerSlider/query";
import { Head } from "../../components/Head";
import { ContentLayout } from "../../components/Layout";
import { NextLink } from "../../components/NextLink";
import { useMobile } from "../../hooks/useMobile";
import { useWindowSize } from "../../hooks/useWindowSize";
import { pagesPath } from "../../lib/$path";
import { IArticle } from "../../lib/interfaces/Article";
import { ICompany } from "../../lib/interfaces/Company";
import { ISeminar } from "../../lib/interfaces/Seminar";
import { device, getNowISO } from "../../utils";
import AchievementArea from "./components/AchievementArea";
import HeroArea from "./components/HeroArea";
import NewArticlesArea from "./components/NewArticlesArea";
import PresenterArea from "./components/PresenterArea";
import SeminarArea from "./components/SeminarArea";
import SponsoredSeminarArea from "./components/SponsoredSeminarArea";
import SupporterArea from "./components/SupporterArea";
import UpcomerArea from "./components/UpcomerArea";

const currentTime = getNowISO();

type Props = {
  articles: IArticle[];
  sponsoredSeminars: ISeminar[];
  supporters: ICompany[];
};

const Template: FunctionComponent<Props> = ({
  articles,
  sponsoredSeminars,
  supporters,
}) => {
  const { width: windowWidth } = useWindowSize();
  const { isMobile } = useMobile();
  const { data: banners } = useGetBanners({
    key: "A1",
    path: "/",
    currentTime,
  });

  return (
    <>
      <Head
        title="トップ"
        description="個人の可能性最大化を実現するために「自身の方向性を描くキャリアデザイン」「キャリアデザインを実現するための人財育成」「選択肢を選択肢化するためのトレーニング」「質の高い成長機会提供・情報提供」を基本とした本質的アプローチを行なっています。"
        href={pagesPath.$url()}
      />
      <_NextLink href="/companies/ibeck">
        <HeroImage
          src={
            isMobile() ? "/images/hero-v2-mobile.png" : "/images/hero-v2.png"
          }
          alt="hero"
          priority
          fill
        />
      </_NextLink>
      <ContentLayout breadcrumbs={[]} breadcrumbsBottom>
        <>
          <HeroArea />
          <UpcomerArea />
          {banners && banners.length > 0 && (
            <BannerSliderWrapper>
              <_BannerSlider banners={banners} width={windowWidth} />
            </BannerSliderWrapper>
          )}
          <AchievementArea />
          <SeminarArea category="lecture" />
          <PresenterArea />
          <SponsoredSeminarArea sponsoredSeminars={sponsoredSeminars} />
          <SupporterArea supporters={supporters} />
          <NewArticlesArea articles={articles} />
        </>
      </ContentLayout>
    </>
  );
};

const _NextLink = styled(NextLink)`
  display: block;
  width: 100vw;
  height: 100%;
  margin: 0;
  padding: 0;

  @media ${device.pc} {
    height: calc(100vw * 0.307);
  }
`;

const HeroImage = styled(Image)`
  object-fit: contain;
  position: relative !important;
  z-index: -1;
`;

const BannerSliderWrapper = styled.div`
  width: 100%;
  height: 330px;

  @media ${device.mobile} {
    height: 200px;
  }
`;

const _BannerSlider = styled(BannerSlider)<{ width: number }>`
  position: absolute;
  width: 100%;
  left: 0;
  margin-top: 80px;

  @media ${device.pc} {
    min-width: 1000px;
  }

  @media ${device.mobile} {
    width: 100%;
    margin-top: 0px;
  }
`;

export default Template;
