import styled from "@emotion/styled";
import { Button } from "@ibeckinc/ui-elements";
import React, { FunctionComponent } from "react";

import { ArticleList } from "../../../components/ArticleList";
import { NextLink } from "../../../components/NextLink";
import { pagesPath } from "../../../lib/$path";
import { IArticle } from "../../../lib/interfaces/Article";
import { device } from "../../../utils";
import TitleAndDescription from "./TitleAndDescription";

type Props = {
  articles: IArticle[];
};

const NewArticlesArea: FunctionComponent<Props> = () => (
  <Root>
    <TitleAndDescription
      title="新着記事"
      body="戦略コンサルティングファームや投資銀行、事業系企業に関する就活情報を掲載しています。"
    />
    <ArticleList align="left" limit={6} paginate={false} />
    <ButtonWrapper>
      <NextLink href={pagesPath.articles.$url()}>
        <_Button>記事一覧へ</_Button>
      </NextLink>
    </ButtonWrapper>
  </Root>
);

const Root = styled.section`
  width: 1000px;
  margin: 100px auto;

  @media ${device.mobile} {
    width: 100%;
    margin: 60px auto;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const _Button = styled(Button)`
  width: 200px;
`;

export default NewArticlesArea;
