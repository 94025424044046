import styled from "@emotion/styled";
import { Chip, Typography } from "@ibeckinc/ui-elements";
import Image from "next/image";
import React, { FunctionComponent } from "react";

import { pagesPath } from "../../lib/$path";
import { PickUpArticle } from "../../types/article";
import { NextLink } from "../NextLink";

const { Paragraphs, Text } = Typography;

const WIDTH = "646px";
const HEIGHT = "426px";

type Props = {
  className?: string;
  article: PickUpArticle;
};

export const PickUpArticleCard: FunctionComponent<Props> = ({
  className,
  article,
}) => {
  return (
    <Root
      className={className}
      href={pagesPath.articles
        ._id(encodeURIComponent(article.urlKeyword ?? article.id))
        .$url()}
    >
      <Image fill src={article.thumbnailUrl} alt={article.title} priority />
      <ContentWrapper>
        <Content>
          <Paragraphs color="white" bold="bold" size="big" multiline>
            {article.title}
          </Paragraphs>
          <TagsWrapper>
            {article.tags.map((tag) => (
              <Chip
                key={`${article.id}-${tag.id}`}
                label={tag.name}
                variant="transparent"
              />
            ))}
          </TagsWrapper>
          <Text variant="caption" color="white">
            {article.author}
          </Text>
        </Content>
      </ContentWrapper>
    </Root>
  );
};

const Root = styled(NextLink)`
  position: relative;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  width: ${WIDTH};
  height: ${HEIGHT};
  overflow: hidden;
  text-decoration: none;
`;

const ContentWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.75) 100%
  );
`;

const Content = styled.div`
  position: absolute;
  display: inline-flex;
  gap: 16px;
  flex-direction: column;
  padding: 24px;
`;

const TagsWrapper = styled.span`
  display: inline-flex;
  gap: 8px;
`;
