import { GetServerSideProps, NextPage } from "next";
import React from "react";

import { searchArticle, SearchArticleKey } from "../components/ArticleList";
import {
  getFeaturedSeminarsByCategory,
  GetFeaturedSeminarsByCategoryKey,
} from "../components/SeminarList";
import { IArticle } from "../lib/interfaces/Article";
import { ICompany } from "../lib/interfaces/Company";
import { ISeminar, SEMINAR_CATEGORY } from "../lib/interfaces/Seminar";
import { DefaultPageProps, getCommonServerSideProps } from "../lib/ssrProps";
import { getSupporterCompanies } from "../requests/companyApi";
import { getFeaturedByCategory } from "../requests/seminarApi";
import Template from "../templates/top";

type Props = {
  articles: IArticle[];
  sponsoredSeminars: ISeminar[];
  supporters: ICompany[];
} & DefaultPageProps;

const TopPage: NextPage<Props> = ({
  articles,
  sponsoredSeminars,
  supporters,
}) => (
  <Template
    articles={articles}
    sponsoredSeminars={sponsoredSeminars}
    supporters={supporters}
  />
);

export const getServerSideProps: GetServerSideProps = async (context) =>
  getCommonServerSideProps(context, async () => {
    const [articles, seminars, sponsoredSeminars, supporters] =
      await Promise.all([
        searchArticle({ limit: 10 }),
        getFeaturedSeminarsByCategory({ category: "lecture" }),
        getFeaturedByCategory({ category: SEMINAR_CATEGORY.sponsored }),
        getSupporterCompanies(),
      ]);

    return {
      props: {
        articles,
        sponsoredSeminars,
        supporters,
        fallback: {
          [SearchArticleKey({ limit: 10 })]: articles,
          [GetFeaturedSeminarsByCategoryKey("lecture")]: seminars,
        },
      },
    };
  });

export default TopPage;
