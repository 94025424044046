import { Session } from "next-auth";
import useSWR from "swr";

import { getFetcher, useFetcher } from "../../hooks/useFetcher";
import { buildAuthHeader } from "../../lib/apiClient";
import { ArticleResult, ArticleResultResponse } from "../../types/article";
import { decode, url } from "../../utils";

export const SearchArticleKey = (args: {
  excludeIds?: string[];
  limit?: number;
  page?: number;
  tagIds?: string[];
}) => url("/articles/v2/search", { ...args });

export const useSearchArticle = ({
  session,
  excludeIds,
  limit,
  page,
  tagIds,
}: {
  session?: Session | null;
  excludeIds?: string[];
  limit?: number;
  page?: number;
  tagIds?: string[];
}) => {
  return useSWR<ArticleResult>(
    SearchArticleKey({ excludeIds, limit, page, tagIds }),
    useFetcher<ArticleResult>(buildAuthHeader(session), ({ data }) =>
      decode(ArticleResultResponse.safeParse(data)),
    ),
  );
};

export async function searchArticle({
  session,
  excludeIds,
  limit,
  page,
  tagIds,
}: {
  session?: Session | null;
  excludeIds?: string[];
  limit?: number;
  page?: number;
  tagIds?: string[];
}): Promise<ArticleResult> {
  const fetcher = getFetcher<ArticleResult>(
    buildAuthHeader(session),
    ({ data }) => decode(ArticleResultResponse.safeParse(data)),
  );
  return fetcher(SearchArticleKey({ excludeIds, limit, page, tagIds }));
}
