import styled from "@emotion/styled";
import { Typography } from "@ibeckinc/ui-elements";
import React, { FunctionComponent } from "react";

import { device } from "../../../utils/device";

const { Text, Title } = Typography;

export interface Props {
  className?: string;
  title: string;
  body: string;
}

const TitleAndDescription: FunctionComponent<Props> = ({
  className,
  title,
  body,
}) => (
  <Root className={className}>
    <Title level={2} multiline>
      {title}
    </Title>
    <BodyContainer>
      <Text variant="body" multiline>
        {body}
      </Text>
    </BodyContainer>
  </Root>
);

const Root = styled.div`
  @media ${device.mobile} {
    margin: 0px;
  }
`;

const BodyContainer = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;

  @media ${device.mobile} {
    margin-top: 16px;
    margin-bottom: 32px;
  }
`;

export default TitleAndDescription;
