import styled from "@emotion/styled";
import {
  Card,
  CardDirection,
  CardSize,
  Chip,
  Typography,
} from "@ibeckinc/ui-elements";
import Image from "next/image";
import React, { FunctionComponent, useMemo } from "react";

import { pagesPath } from "../../lib/$path";
import { DATE_FORMAT } from "../../lib/constants/Date";
import { Article, PickUpArticle } from "../../types/article";
import { formatDate, truncate } from "../../utils";
import { NextLink } from "../NextLink";

const { Paragraphs, Text } = Typography;

type Props = {
  article: Article | PickUpArticle;
  className?: string;
  direction?: CardDirection;
  footer?: React.ReactNode;
  highlightTagId?: string;
};

export const ArticleCard: FunctionComponent<Props> = ({
  article,
  className,
  direction = "vertical",
  footer,
  highlightTagId,
}) => {
  const { id, title, author, thumbnailUrl, publishedAt, tags, urlKeyword } =
    article;
  const truncatetedTitle = useMemo(() => truncate(title, 64), [title]);

  return (
    <Root
      className={className}
      _variant={direction}
      href={pagesPath.articles._id(encodeURIComponent(urlKeyword ?? id)).$url()}
    >
      <_Card
        direction={direction}
        _variant={direction}
        footer={footer}
        image={
          <ImageWrapper>
            <_Image
              src={thumbnailUrl}
              alt={title}
              width={CardSize.medium.width}
              height={CardSize.medium.height}
              priority
            />
          </ImageWrapper>
        }
      >
        <Content>
          <Caption>
            <Text variant="caption" color="light">
              {author}
            </Text>
            {direction === "vertical" && (
              <Text variant="caption" color="light">
                {formatDate(publishedAt, DATE_FORMAT.JP_SIMPLE_DATE_FORMAT)}
              </Text>
            )}
          </Caption>
          <Paragraphs bold="bold" multiline>
            {truncatetedTitle}
          </Paragraphs>
          <ChipBox>
            {tags.map(({ id, name }) => (
              <Chip
                key={`${article.id}-${id}`}
                label={name}
                variant={id === highlightTagId ? "highlight" : "default"}
              />
            ))}
          </ChipBox>
        </Content>
      </_Card>
    </Root>
  );
};

const Root = styled(NextLink)<{
  _variant: CardDirection;
}>`
  display: block;
  width: ${({ _variant }) =>
    _variant === "horizontal" ? "100%" : `${CardSize.medium.width}px`};
`;

const _Card = styled(Card)<{
  _variant: CardDirection;
}>`
  overflow: hidden;
  width: ${({ _variant }) =>
    _variant === "horizontal" ? "100%" : `${CardSize.medium.width}px`};
  height: ${({ _variant }) =>
    _variant === "horizontal" ? CardSize.medium.height : "100%"};
  min-height: ${({ _variant }) =>
    _variant === "horizontal" ? "none" : "428px"};
`;

const ImageWrapper = styled.div`
  height: ${CardSize.medium.height}px;
  min-height: ${CardSize.medium.height}px;
  max-height: ${CardSize.medium.height}px;
  width: ${CardSize.medium.width}px;
  min-width: ${CardSize.medium.width}px;
  max-width: ${CardSize.medium.width}px;
  overflow: hidden;
`;

const _Image = styled(Image)`
  object-fit: contain;
  object-position: center top;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
  width: 100%;
`;

const Caption = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ChipBox = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  margin-top: 8px;
  gap: 8px;
  height: 100%;
`;
