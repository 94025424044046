import * as React from "react";

const AboutSeminarIcon: React.SVGAttributes<SVGElement> & React.FC = ({}) => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="27.9688" cy="52.0317" r="22.9688" fill="#E6F8EB" />
    <path
      d="M70.6255 37.8125H29.063V35.625H68.438V15.9375H29.063V13.75H70.6255V37.8125Z"
      fill="#5DBB63"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.1255 18.125C14.5063 18.125 11.563 15.1817 11.563 11.5625C11.563 7.94327 14.5063 5 18.1255 5C21.7447 5 24.688 7.94327 24.688 11.5625C24.688 15.1817 21.7447 18.125 18.1255 18.125ZM18.1255 7.1875C15.7127 7.1875 13.7505 9.14969 13.7505 11.5625C13.7505 13.9753 15.7127 15.9375 18.1255 15.9375C20.5383 15.9375 22.5005 13.9753 22.5005 11.5625C22.5005 9.14969 20.5383 7.1875 18.1255 7.1875Z"
      fill="#5DBB63"
    />
    <path
      d="M75 46.5625H24.6875V26.875H42.1875V22.5H13.75C10.1308 22.5 7.1875 25.4433 7.1875 29.0625V44.375H11.5625V35.625H13.75V46.5625H5V29.0625C5 24.238 8.92547 20.3125 13.75 20.3125H44.375V29.0625H26.875V44.375H72.8125V11.5625H26.875V9.375H75V46.5625Z"
      fill="#5DBB63"
    />
    <rect x="29.063" y="40" width="41.5625" height="2.1875" fill="#5DBB63" />
    <path
      d="M71.7188 75.0009H8.28125C6.50282 75.0009 5 72.9971 5 70.6259V67.804L13.2075 63.114L14.2925 65.0128L7.18751 69.0728V70.6259C7.18751 71.9646 7.95642 72.8134 8.28126 72.8134H71.7188C72.0436 72.8134 72.8125 71.9646 72.8125 70.6259V69.0728L65.7075 65.0128L66.7925 63.114L75 67.804V70.6259C75 72.9971 73.4972 75.0009 71.7188 75.0009H71.7188Z"
      fill="#5DBB63"
    />
    <path
      d="M52.0655 69.6784L42.792 65.0417L43.7704 63.085L51.9971 67.1982L59.1449 63.1137L60.2301 65.0129L52.0655 69.6784Z"
      fill="#5DBB63"
    />
    <path
      d="M27.9347 69.6784L19.77 65.0129L20.8552 63.1137L28.0031 67.1982L36.2296 63.085L37.2081 65.0417L27.9347 69.6784Z"
      fill="#5DBB63"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0317 66.2505C13.2911 66.2505 10.4692 62.7242 10.4692 58.0474C10.4692 54.7377 11.2152 50.938 17.0317 50.938C22.8483 50.938 23.5942 54.7377 23.5942 58.0474C23.5942 62.7242 20.7724 66.2505 17.0317 66.2505V66.2505ZM17.0317 53.1255C13.7199 53.1255 12.6567 54.3209 12.6567 58.0474C12.6567 60.9644 14.1902 64.063 17.0317 64.063C19.8733 64.063 21.4067 60.9644 21.4067 58.0474C21.4067 54.321 20.3436 53.1255 17.0317 53.1255V53.1255Z"
      fill="#5DBB63"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.0005 66.2505C36.2599 66.2505 33.438 62.7242 33.438 58.0474C33.438 54.7377 34.1839 50.938 40.0005 50.938C45.8171 50.938 46.563 54.7377 46.563 58.0474C46.563 62.7242 43.7411 66.2505 40.0005 66.2505V66.2505ZM40.0005 53.1255C36.6886 53.1255 35.6255 54.3209 35.6255 58.0474C35.6255 60.9644 37.1589 64.063 40.0005 64.063C42.842 64.063 44.3755 60.9644 44.3755 58.0474C44.3755 54.321 43.3124 53.1255 40.0005 53.1255V53.1255Z"
      fill="#5DBB63"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62.9683 66.2505C59.2276 66.2505 56.4058 62.7242 56.4058 58.0474C56.4058 54.7377 57.1517 50.938 62.9683 50.938C68.7848 50.938 69.5308 54.7377 69.5308 58.0474C69.5308 62.7242 66.7089 66.2505 62.9683 66.2505V66.2505ZM62.9683 53.1255C59.6564 53.1255 58.5933 54.3209 58.5933 58.0474C58.5933 60.9644 60.1267 64.063 62.9683 64.063C65.8098 64.063 67.3433 60.9644 67.3433 58.0474C67.3433 54.321 66.2801 53.1255 62.9683 53.1255V53.1255Z"
      fill="#5DBB63"
    />
    <rect
      x="26.875"
      y="68.438"
      width="2.1875"
      height="3.28125"
      fill="#5DBB63"
    />
    <rect
      x="50.938"
      y="68.438"
      width="2.1875"
      height="3.28125"
      fill="#5DBB63"
    />
    <rect
      x="17.0308"
      y="24.688"
      width="2.1875"
      height="2.1875"
      fill="#5DBB63"
    />
    <rect
      x="17.0308"
      y="29.063"
      width="2.1875"
      height="2.1875"
      fill="#5DBB63"
    />
  </svg>
);

export default AboutSeminarIcon;
