import styled from "@emotion/styled";
import { Button, Color } from "@ibeckinc/ui-elements";
import React, { FunctionComponent } from "react";

import { useMobile } from "../../../hooks/useMobile";
import { pagesPath } from "../../../lib/$path";
import { device } from "../../../utils";
import AboutGrowthIcon from "./icons/AboutGrowthIcon";
import AboutPeopleIcon from "./icons/AboutPeopleIcon";
import AboutSeminarIcon from "./icons/AboutSeminarIcon";
import AboutSupporterIcon from "./icons/AboutSupporterIcon";
import TitleAndDescription from "./TitleAndDescription";
import UpcomerCard, { Props as UpcomerCardProps } from "./UpcomerCard";

const UpcomerCardAttributes: UpcomerCardProps[] = [
  {
    icon: <AboutGrowthIcon />,
    title: "厳選企業との成長機会",
    body: "「成長機会の提供」を本気で考えている企業と共同で、日本唯一/日本一位の機会提供をしています。",
  },
  {
    icon: <AboutPeopleIcon />,
    title: "最優秀の仲間",
    body: "日本で最も優秀な同世代の仲間や、現役で働いている社会人・協力企業との交流を促進しています。",
  },
  {
    icon: <AboutSeminarIcon />,
    title: "質の高いセミナー",
    body: "各業界出身者監修のもと、ビジネスプロフェッショナルを見据えたアウトプット重視型のセミナーを実施しています。",
  },

  {
    icon: <AboutSupporterIcon />,
    title: "きめ細かいサポート",
    body: "外資系企業出身者・外資系企業内定者等がメンターとなり、日常での選考対策や思考力向上、キャリア相談などを行います。",
  },
];

const UpcomerArea: FunctionComponent = () => {
  const { isMobile } = useMobile();

  return (
    <Root>
      <_TitleAndDescription
        title="初めての方へ"
        body={
          isMobile()
            ? `Alternative Careersは、個人の可能性最大化を実現するために、\n「自身の方向性を描くキャリアデザイン」\n「キャリアデザインを実現するための人財育成」\n「選択肢を選択肢化するためのトレーニング」\n「質の高い成長機会提供・情報提供」\nを基本とした本質的アプローチを行なっています。\n「就職支援」をただするのではなく、日本のための「人財育成」を実現する生涯学習のプラットフォームを運営しております`
            : `Alternative Careersは、個人の可能性最大化を実現するために、\n「自身の方向性を描くキャリアデザイン」「キャリアデザインを実現するための人財育成」\n「選択肢を選択肢化するためのトレーニング」「質の高い成長機会提供・情報提供」\nを基本とした本質的アプローチを行なっています。\n「就職支援」をただするのではなく、日本のための「人財育成」を実現する生涯学習のプラットフォームを運営しております`
        }
      />
      <UpcomerCardsArea>
        <UpcomerCardSlidable>
          {UpcomerCardAttributes.map((attr, i) => (
            <UpcomerCard key={i} {...attr} />
          ))}
        </UpcomerCardSlidable>
      </UpcomerCardsArea>
      <ButtonWrapper>
        <_Button href={pagesPath.sign_up.$url().pathname}>会員登録する</_Button>
      </ButtonWrapper>
    </Root>
  );
};

const _TitleAndDescription = styled(TitleAndDescription)`
  width: 1000px;
  margin: 120px auto 59px;

  @media ${device.mobile} {
    width: auto;
    margin: 0px;
  }
`;

const Root = styled.section`
  position: relative;

  @media ${device.mobile} {
    margin: 64px 0;
  }
`;

const UpcomerCardsArea = styled.div`
  box-sizing: border-box;
  height: 310px;
  margin-left: 113px;
  padding-top: 32px;
  background: ${Color.Background.LightBrand};

  @media ${device.mobile} {
    margin-left: 0;
    padding-top: 16px;
    padding-left: 20px;
  }
`;

const UpcomerCardSlidable = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-right: 113px;
  gap: 24px;

  @media ${device.mobile} {
    justify-content: flex-start;
    margin-right: 0;
    overflow-x: scroll;
    overflow-y: hidden;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 102px;

  @media ${device.mobile} {
    margin-top: 48px;
  }
`;

const _Button = styled(Button)`
  width: 200px;
`;

export default UpcomerArea;
