import styled from "@emotion/styled";
import React, { FunctionComponent } from "react";

import { EmployeeCard } from "../../../components/EmployeeCard";
import Heading from "../../../components/Heading";
import { IPresenter } from "../../../lib/interfaces/Seminar";
import { device } from "../../../utils";

type Props = {
  className?: string;
};

const presenters: IPresenter[] = [
  {
    name: "竹中 淳人",
    image: "/images/portrait-takenaka.jpeg",
    company: "株式会社iBECK",
    introduction:
      "2011年から人事採用責任者として、チームメンバー45名をマネジメントする。年間応募者数を800％UPの8万人まで拡大し、日本史上唯一の結果を残す。",
    position: "代表取締役",
    message: "",
    tags: [
      {
        id: "1",
        name: "外資系コンサル",
      },
    ],
  },
  {
    name: "廣川 克也",
    image: "/images/portrait-hirokawa.jpeg",
    company: "SFCフォーラムファンド",
    introduction: "",
    position: "ファンドマネージャー",
    message: "",
    tags: [
      {
        id: "1",
        name: "外資系コンサル",
      },
      {
        id: "2",
        name: "起業家",
      },
    ],
  },
  {
    name: "守屋 智紀",
    image: "/images/portrait-moriya.jpeg",
    company: "AIスタートアップ",
    introduction: "",
    position: "経営企画部長",
    message: "",
    tags: [
      {
        id: "1",
        name: "経営企画",
      },
      {
        id: "2",
        name: "M&A",
      },
    ],
  },
];

const PresenterArea: FunctionComponent<Props> = ({ className }) => {
  return (
    <Root>
      <Heading.H3>講師陣</Heading.H3>
      <ListContainer className={className}>
        {presenters.map((p, i) => {
          return (
            <_EmployeeCard
              key={i}
              name={p.name}
              image={p.image}
              role={p.position}
              companyName={p.company}
              tags={p.tags}
            />
          );
        })}
      </ListContainer>
    </Root>
  );
};

const Root = styled.section`
  margin: 0 auto;
  width: 1000px;

  @media ${device.mobile} {
    width: 100%;
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;

  @media ${device.mobile} {
    justify-content: normal;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;

    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const _EmployeeCard = styled(EmployeeCard)`
  margin-right: 20px;
`;

export default PresenterArea;
