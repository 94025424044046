import { RefObject, useEffect } from "react";

export const useResizeObserver = (
  elements: RefObject<HTMLElement>[],
  callback: (entries: ResizeObserverEntry[]) => void,
) => {
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      callback(entries);
    });

    for (const elem of elements) {
      elem.current && resizeObserver.observe(elem.current);
    }

    return () => resizeObserver.disconnect();
  }, [callback, elements]);
};
