import * as React from "react";

const AboutSupporterIcon: React.SVGAttributes<SVGElement> & React.FC = ({}) => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="52.0308" cy="52.0317" r="22.9688" fill="#FFF6E9" />
    <rect
      x="56.4058"
      y="49.844"
      width="2.1875"
      height="25.1562"
      fill="#F2BB27"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.5005 18.125C53.8813 18.125 50.938 15.1817 50.938 11.5625C50.938 7.94327 53.8813 5 57.5005 5C61.1197 5 64.063 7.94327 64.063 11.5625C64.063 15.1817 61.1197 18.125 57.5005 18.125ZM57.5005 7.1875C55.0877 7.1875 53.1255 9.14969 53.1255 11.5625C53.1255 13.9753 55.0877 15.9375 57.5005 15.9375C59.9133 15.9375 61.8755 13.9753 61.8755 11.5625C61.8755 9.14969 59.9133 7.1875 57.5005 7.1875Z"
      fill="#F2BB27"
    />
    <path
      d="M66.2503 75.0005H64.0628V45.4692H68.4378V29.063C68.4378 25.4438 65.4945 22.5005 61.8753 22.5005H53.1253C49.3792 22.5005 47.4914 25.2666 47.4728 25.295L38.9175 38.2943L37.0898 37.0922L45.6495 24.0864C45.7556 23.9278 48.222 20.313 53.1253 20.313H61.8753C66.6999 20.313 70.6253 24.2384 70.6253 29.063V47.6567H66.2503V75.0005Z"
      fill="#F2BB27"
    />
    <rect
      x="56.4058"
      y="24.688"
      width="2.1875"
      height="2.1875"
      fill="#F2BB27"
    />
    <rect
      x="56.4058"
      y="29.063"
      width="2.1875"
      height="2.1875"
      fill="#F2BB27"
    />
    <rect
      x="17.0308"
      y="49.844"
      width="2.1875"
      height="25.1562"
      fill="#F2BB27"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0093 18.125C14.3912 18.125 11.4468 15.1817 11.4468 11.5625C11.4468 7.94327 14.3912 5 18.0093 5C21.6285 5 24.5718 7.94327 24.5718 11.5625C24.5718 15.1817 21.6285 18.125 18.0093 18.125ZM18.0093 7.1875C15.5976 7.1875 13.6343 9.14969 13.6343 11.5625C13.6343 13.9753 15.5976 15.9375 18.0093 15.9375C20.4221 15.9375 22.3843 13.9753 22.3843 11.5625C22.3843 9.14969 20.4221 7.1875 18.0093 7.1875Z"
      fill="#F2BB27"
    />
    <path
      d="M11.5625 75.0005H9.375V47.6567H5V29.063C5 24.2384 8.87297 20.313 13.6341 20.313H22.3841C27.4022 20.313 29.8697 23.9278 29.9725 24.081L41.995 41.5689L40.1925 42.8071L28.1613 25.3071C28.0781 25.1856 26.2013 22.5005 22.3841 22.5005H13.6341C10.0192 22.5005 7.1875 25.3836 7.1875 29.063V45.4692H11.5625V75.0005Z"
      fill="#F2BB27"
    />
    <path
      d="M50.938 74.9997H48.7505V36.0776L38.5863 46.2418C38.1586 46.6694 37.4674 46.6694 37.0397 46.2418L26.8755 36.0776V74.9997H24.688V33.4372C24.688 32.9954 24.9549 32.5961 25.3628 32.4266C25.7719 32.2571 26.2411 32.35 26.555 32.664L37.813 43.9219L49.071 32.664C49.3838 32.3501 49.8519 32.2571 50.2631 32.4266C50.6711 32.5961 50.938 32.9954 50.938 33.4372L50.938 74.9997Z"
      fill="#F2BB27"
    />
  </svg>
);

export default AboutSupporterIcon;
