import styled from "@emotion/styled";
import {
  CardDirection,
  CircularProgress,
  Pagination,
} from "@ibeckinc/ui-elements";
import { useSession } from "next-auth/react";
import React, { FunctionComponent, useCallback } from "react";

import { useMobile } from "../../hooks/useMobile";
import { device } from "../../utils";
import { ArticleCard } from "../ArticleCard";
import Heading from "../Heading";
import { NoResult } from "../NoResult";
import { useSearchArticle } from "./query";

type Props = {
  align?: "center" | "left";
  excludeIds?: string[];
  direction?: CardDirection;
  hideEmpty?: boolean;
  highlightTagId?: string;
  limit?: number;
  page?: number;
  paginate?: boolean;
  tagId?: string;
  title?: string;
  onPageChange?: (page: number) => void;
};

export const ArticleList: FunctionComponent<Props> = ({
  align = "center",
  excludeIds,
  direction = "horizontal",
  hideEmpty = false,
  highlightTagId,
  limit = 10,
  page = 1,
  paginate = true,
  tagId,
  title,
  onPageChange,
}) => {
  const { isMobile } = useMobile();
  const { data: session } = useSession();

  const { data } = useSearchArticle({
    session,
    excludeIds,
    limit,
    page,
    tagIds: highlightTagId ? [highlightTagId] : tagId ? [tagId] : undefined,
  });

  const handleChangePage = useCallback(
    (_: React.MouseEvent<HTMLButtonElement, MouseEvent>, page: number) => {
      onPageChange && onPageChange(page);
    },
    [onPageChange],
  );

  if (data?.total === 0) {
    return hideEmpty ? null : (
      <>
        {title && <Heading.H2>{title}</Heading.H2>}
        <NoResultContainer>
          <_NoResult>ご指定の条件では記事がありません</_NoResult>
        </NoResultContainer>
      </>
    );
  } else if (data?.data.length === 0) {
    return (
      <>
        {title && <Heading.H2>{title}</Heading.H2>}
        <NoResultContainer>
          <_NoResult>このページには記事がありません</_NoResult>
        </NoResultContainer>
      </>
    );
  }

  return (
    <Root>
      {title && <Heading.H2>{title}</Heading.H2>}
      <React.Suspense fallback={<CircularProgress />}>
        {data && (
          <>
            <ArticleCardContainer align={align} direction={direction}>
              {data.data.map((article) => (
                <ArticleCard
                  key={article.id}
                  article={article}
                  direction={
                    isMobile()
                      ? "vertical"
                      : direction === "horizontal"
                        ? "vertical"
                        : "horizontal"
                  }
                  highlightTagId={highlightTagId}
                />
              ))}
            </ArticleCardContainer>
            {paginate && onPageChange && (
              <_Pagination
                page={page}
                total={Math.ceil(data.total / limit)}
                onPageChange={handleChangePage}
              />
            )}
          </>
        )}
      </React.Suspense>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
`;

const NoResultContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
`;

const _NoResult = styled(NoResult)`
  margin-top: 48px;
`;

const ArticleCardContainer = styled.div<{
  align: "center" | "left";
  direction: CardDirection;
}>`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ align, direction }) =>
    direction === "horizontal"
      ? align === "left"
        ? "flex-start"
        : "center"
      : "space-between"};
  align-items: center;
  width: 100%;
  margin-top: 32px;
  gap: 48px 32px;

  @media ${device.mobile} {
    justify-content: center;
  }
`;

const _Pagination = styled(Pagination)`
  margin-top: 48px;
`;

export default ArticleCardContainer;
