import * as React from "react";

const AboutPeopleIcon: React.SVGAttributes<SVGElement> & React.FC = ({}) => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="31.9688" cy="27.9688" r="22.9688" fill="#FFEBEB" />
    <rect
      x="38.5308"
      y="52.031"
      width="2.1875"
      height="22.9688"
      fill="#D32D46"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.6255 18.125C36.0063 18.125 33.063 15.1817 33.063 11.5625C33.063 7.94327 36.0063 5 39.6255 5C43.2447 5 46.188 7.94327 46.188 11.5625C46.188 15.1817 43.2447 18.125 39.6255 18.125ZM39.6255 7.1875C37.2127 7.1875 35.2505 9.14969 35.2505 11.5625C35.2505 13.9753 37.2127 15.9375 39.6255 15.9375C42.0383 15.9375 44.0005 13.9753 44.0005 11.5625C44.0005 9.14969 42.0383 7.1875 39.6255 7.1875Z"
      fill="#D32D46"
    />
    <path
      d="M52.75 29.063H50.5625V27.9692C50.5625 24.9538 48.1092 22.5005 45.0938 22.5005H34.1562C31.1408 22.5005 28.6875 24.9538 28.6875 27.9692V29.063H26.5V27.9692C26.5 23.7484 29.9344 20.313 34.1562 20.313H45.0938C49.3156 20.313 52.75 23.7484 52.75 27.9692V29.063Z"
      fill="#D32D46"
    />
    <rect
      x="19.938"
      y="56.406"
      width="2.1875"
      height="18.5938"
      fill="#D32D46"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.0317 25.7815C18.0163 25.7815 15.563 23.3282 15.563 20.3127C15.563 17.2973 18.0163 14.844 21.0317 14.844C24.0472 14.844 26.5005 17.2973 26.5005 20.3127C26.5005 23.3282 24.0472 25.7815 21.0317 25.7815ZM21.0317 17.0315C19.2227 17.0315 17.7505 18.5037 17.7505 20.3127C17.7505 22.1218 19.2227 23.594 21.0317 23.594C22.8408 23.594 24.313 22.1218 24.313 20.3127C24.313 18.5037 22.8408 17.0315 21.0317 17.0315Z"
      fill="#D32D46"
    />
    <path
      d="M28.6875 75.0002H26.5V52.0315H30.875V34.5315C30.875 32.1187 28.9128 30.1565 26.5 30.1565H15.5625C13.1497 30.1565 11.1875 32.1187 11.1875 34.5315V52.0315H15.5625V75.0002H13.375V54.219H9V34.5315C9 30.9123 11.9433 27.969 15.5625 27.969H26.5C30.1192 27.969 33.0625 30.9123 33.0625 34.5315V54.219H28.6875V75.0002Z"
      fill="#D32D46"
    />
    <rect
      x="57.125"
      y="56.406"
      width="2.1875"
      height="18.5938"
      fill="#D32D46"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.2188 25.7815C55.2033 25.7815 52.75 23.3282 52.75 20.3127C52.75 17.2973 55.2033 14.844 58.2188 14.844C61.2342 14.844 63.6875 17.2973 63.6875 20.3127C63.6875 23.3282 61.2342 25.7815 58.2188 25.7815ZM58.2188 17.0315C56.4097 17.0315 54.9375 18.5037 54.9375 20.3127C54.9375 22.1218 56.4097 23.594 58.2188 23.594C60.0278 23.594 61.5 22.1218 61.5 20.3127C61.5 18.5037 60.0278 17.0315 58.2188 17.0315Z"
      fill="#D32D46"
    />
    <path
      d="M65.8755 75.0002H63.688V52.0315H68.063V34.5315C68.063 32.1187 66.1008 30.1565 63.688 30.1565H52.7505C50.3377 30.1565 48.3755 32.1187 48.3755 34.5315V52.0315H52.7505V75.0002H50.563V54.219H46.188V34.5315C46.188 30.9123 49.1313 27.969 52.7505 27.969H63.688C67.3072 27.969 70.2505 30.9123 70.2505 34.5315V54.219H65.8755V75.0002Z"
      fill="#D32D46"
    />
    <rect
      x="38.5308"
      y="24.688"
      width="2.1875"
      height="2.1875"
      fill="#D32D46"
    />
    <rect
      x="38.5308"
      y="29.063"
      width="2.1875"
      height="2.1875"
      fill="#D32D46"
    />
    <rect x="57.125" y="32.344" width="2.1875" height="2.1875" fill="#D32D46" />
    <rect x="19.938" y="32.344" width="2.1875" height="2.1875" fill="#D32D46" />
    <rect x="30.875" y="53.125" width="2.1875" height="21.875" fill="#D32D46" />
    <rect x="46.188" y="53.125" width="2.1875" height="21.875" fill="#D32D46" />
  </svg>
);

export default AboutPeopleIcon;
