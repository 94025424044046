import styled from "@emotion/styled";
import { Button } from "@ibeckinc/ui-elements";
import React, { FunctionComponent } from "react";

import { NextLink } from "../../../components/NextLink";
import { CompanySeminarCard } from "../../../components/SeminarCard";
import { pagesPath } from "../../../lib/$path";
import { ISeminar } from "../../../lib/interfaces/Seminar";
import { device } from "../../../utils";
import TitleAndDescription from "./TitleAndDescription";

type Props = {
  sponsoredSeminars: ISeminar[];
};

const SponsoredSeminarArea: FunctionComponent<Props> = ({
  sponsoredSeminars,
}) => (
  <Root>
    <TitleAndDescription
      title="企業共催イベント"
      body={`戦略コンサルティングファームや投資銀行、事業系企業と共同で\n業界理解セミナーやビジネスセミナー、特別選考会等最大月15回以上開催しています。\nただ「採用できればいい企業」ではなく、\n「成長機会の提供」を本気で考えている企業に限定して提携しています。`}
    />
    <CardsArea>
      {sponsoredSeminars.length > 0 && (
        <CardContainer>
          {sponsoredSeminars.map((seminar) => (
            <_SeminarCard
              key={`seminar-card-${seminar.id}`}
              id={seminar.id}
              seminarCategory="sponsored"
              tags={seminar.tags}
              thumbnail={seminar.thumbnail}
              title={seminar.title}
              companyName={seminar.company?.name ?? ""}
              logo={seminar.company?.logo}
              urlKeyword={seminar.urlKeyword}
            />
          ))}
        </CardContainer>
      )}
    </CardsArea>
    {sponsoredSeminars.length > 0 && (
      <ButtonWrapper>
        <NextLink href={pagesPath.seminars.$url()}>
          <_Button>もっと見る</_Button>
        </NextLink>
      </ButtonWrapper>
    )}
  </Root>
);

const Root = styled.section`
  width: 1000px;
  margin: 100px auto;

  @media ${device.mobile} {
    width: 100%;
    margin: 60px auto;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const _Button = styled(Button)`
  width: 200px;
`;

const CardsArea = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media ${device.mobile} {
    flex-direction: column;
    width: 100%;
    margin-bottom: 8px;
  }
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding-bottom: 31px;
  gap: 30px;

  @media ${device.mobile} {
    justify-content: center;
    gap: 24px;
  }
`;

const _SeminarCard = styled(CompanySeminarCard)`
  margin-bottom: 30px;
`;

export default SponsoredSeminarArea;
