import styled from "@emotion/styled";
import { Button } from "@ibeckinc/ui-elements";
import React, { FunctionComponent, useMemo } from "react";

import Heading from "../../../components/Heading";
import { NextLink } from "../../../components/NextLink";
import {
  SeminarList,
  useGetFeaturedSeminarsByCategory,
} from "../../../components/SeminarList";
import { useMobile } from "../../../hooks/useMobile";
import { pagesPath } from "../../../lib/$path";
import { device } from "../../../utils";
import TitleAndDescription from "./TitleAndDescription";

type Props = {
  category: string;
};

const SeminarArea: FunctionComponent<Props> = ({ category }) => {
  const { isMobile } = useMobile();

  const { data } = useGetFeaturedSeminarsByCategory({ category });

  const show = useMemo(() => data && data?.length > 0, [data]);

  return (
    <Root>
      <TitleAndDescription
        title={
          isMobile()
            ? "「人財育成」をサポートする\n質の高いセミナーや講師陣"
            : "「人財育成」をサポートする質の高いセミナーや講師陣"
        }
        body={`戦略コンサル・投資銀行・商社・事業系等コース別のセミナーを、各業界出身者が講師として運営してい\nます。\nES・面接・ケース・ジョブなどの選考対策などはもちろん、本質的な思考力向上や戦略的キャリアデザイ\nンセミナー、ビジネススキルセミナー等のキャリアサポートも実施しています。\n「小手先の対策でない本質的なセミナー」を「ビジネスの最前線で活躍する講師」がお届けします。`}
      />
      {show && <Heading.H3>開催セミナー</Heading.H3>}
      <SeminarList category={category} />
      {show && (
        <ShowMore>
          <NextLink href={pagesPath.seminars.$url()}>
            <_Button>もっと見る</_Button>
          </NextLink>
        </ShowMore>
      )}
    </Root>
  );
};

const Root = styled.section`
  width: 1000px;
  margin: 40px auto;

  @media ${device.mobile} {
    width: 100%;
    margin: 60px auto;
  }
`;

const ShowMore = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 48px;
  width: 100%;

  @media ${device.mobile} {
    margin-top: 40px;
  }
`;

const _Button = styled(Button)`
  width: 200px;
`;

export default SeminarArea;
