import styled from "@emotion/styled";
import {
  Card,
  CardSize,
  Chip,
  Color,
  ProfileIcon,
  Typography,
} from "@ibeckinc/ui-elements";
import Image from "next/image";
import React, { FunctionComponent } from "react";

import { ITag } from "../../lib/interfaces/Tag";

const { Text } = Typography;

const ImageHeight = 200;

type Props = {
  className?: string;
  companyName?: string;
  name: string;
  image?: string;
  role: string;
  tags?: ITag[];
};

export const EmployeeCard: FunctionComponent<Props> = ({
  className,
  companyName,
  image,
  name,
  role,
  tags,
}) => {
  return (
    <_Card
      className={className}
      image={
        image ? (
          <Image
            src={image}
            alt={name}
            width={CardSize.small.width}
            height={ImageHeight}
          />
        ) : (
          <UserIconBackground>
            <ProfileIcon size={105} />
          </UserIconBackground>
        )
      }
      size="small"
    >
      <CompanyWrapper>
        {companyName && <Text variant="caption">{companyName}</Text>}
        <Text variant="caption">{role}</Text>
      </CompanyWrapper>
      <NameWrapper>
        <Text variant="button">{name}</Text>
      </NameWrapper>
      {tags && (
        <TagsContainer>
          {tags.map((tag, i) => (
            <Chip key={i} label={tag.name} />
          ))}
        </TagsContainer>
      )}
    </_Card>
  );
};

const _Card = styled(Card)`
  position: relative;
  min-width: ${CardSize.small.width}px;
  overflow: hidden;
`;

const UserIconBackground = styled.div`
  background: ${Color.Background.Grey};
  height: ${ImageHeight}px;
  width: ${CardSize.small.width}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CompanyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
`;

const NameWrapper = styled.div`
  margin-top: 8px;
`;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 24px;
`;
